import React, { useState, useEffect, Fragment } from 'react';
import { toast } from "react-toastify";
import Select from 'react-select';
import { Link, useHistory } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import API from "../../helpers/api";
import FNSpinner from "../../components/FNSpinner";

const AddSupply = () => {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [subTotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [grossTotal, setGrossTotal] = useState(0);
    const [vat, setVat] = useState(0);
    const [netTotal, setNetTotal] = useState(0);
    const [customerId, setCustomerId] = useState("");
    const [customers, setCustomers] = useState([]);
    const [hasvat, setHasVat] = useState(false);
    const [hasdiscount, setHasDiscount] = useState(false);
    const [currency, setCurrency] = useState();

    const history = useHistory();

    const loadCustomers = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/customers`);
            setCustomers(res.data.customers);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const handleCustomer = (selectedOption) => {
        setCustomerId(selectedOption.value);
    };

    const createNewRow = () => ({
        id: Date.now(),
        item: '',
        partno: '',
        qty: '',
        unitprice: '',
        serialno: '',
        amt: '',
    });

    const addRow = () => {
        setRows([...rows, createNewRow()]);
    };

    const deleteRow = (id) => {
        setRows((prevRows) => prevRows.filter((row) => row.id !== id));
    };

    const handleInputChange = (id, field, value) => {
        setRows((prevRows) => prevRows.map((row) => {
            if (row.id === id) {
                const updatedRow = { ...row, [field]: value };
                if (field === 'qty' || field === 'unitprice') {
                    const qty = field === 'qty' ? parseFloat(value) || 0 : parseFloat(updatedRow.qty) || 0;
                    const unitprice = field === 'unitprice' ? parseFloat(value) || 0 : parseFloat(updatedRow.unitprice) || 0;
                    updatedRow.amt = qty * unitprice;
                }
                return updatedRow;
            }
            return row;
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            subTotal,
            discount,
            grossTotal,
            vat,
            netTotal,
            customerId,
            hasdiscount,
            hasvat,
            currency,
            rows: rows.map(row => ({
                item: row.item,
                partno: row.partno,
                serialno: row.serialno,
                qty: row.qty,
                unitprice: row.unitprice,
                amt: row.amt,
            }))
        };

        try {
            const response = await API.post("/supplies", data);
            setLoading(false);
            history.push('/supplies');
            toast.success(`Supplies Have Been Added Successfully`);
        } catch (error) {
            console.log("error", error.response.data);
            setLoading(false);
            toast.error(error.response.data.message);
        }
    };

    useEffect(() => {
        setRows([createNewRow()]);
    }, []);

    useEffect(() => {
        loadCustomers();
    }, []);

    useEffect(() => {
        const calculateTotals = () => {
            const subTotal = rows.reduce((total, row) => total + (parseFloat(row.amt) || 0), 0);
            let discountAmount = 0;
            let netTotal = subTotal;
            let vatAmount = 0;
            let grossTotal = subTotal;

            if (hasdiscount) {
                discountAmount = (discount / 100) * subTotal;
                netTotal = subTotal - discountAmount;
            }

            if (hasvat) {
                vatAmount = netTotal * 0.18;
                grossTotal = netTotal + vatAmount;
            } else {
                grossTotal = netTotal;
            }

            setSubTotal(subTotal);
            setGrossTotal(grossTotal);
            setVat(vatAmount);
            setNetTotal(netTotal);
        };

        calculateTotals();
    }, [rows, discount, hasvat, hasdiscount]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-12">
                    <div className="d-sm-flex align-items-center justify-content-between mb-3">
                        <h4 className="mb-sm-0 font-size-18">Add General Supplies Items</h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/supplies">Back To Supplies</Link></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="mb-3">
                                <label for="kycselectcity-input" class="form-label">Select Customer</label>
                                <Select
                                    defaultValue={customerId}
                                    onChange={handleCustomer}
                                    options={customers.map(customer => ({ value: customer.id, label: customer.fullname }))}
                                    placeholder="Select Customer Name"
                                />
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="mb-3">
                                <label for="kycselectcity-input" class="form-label">Select Currency</label>
                                <select class="form-select" aria-label="Select example" value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}>
                                    <option>Select Currency </option>
                                    <option>UGX</option>
                                    <option>USD</option>
                                    <option>EURO</option>
                                    <option>KES</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className='d-flex'>
                                <h4 className="card-title">
                                    <button className="btn btn-sm btn-soft-primary mx-3" onClick={addRow}>
                                        <i className="mdi mdi-eye-outline"></i>Add Supply Item
                                    </button>
                                </h4>
                                <input className="form-check-input mx-2" type="checkbox"
                                    id="formvat" checked={hasvat} onChange={(e) => setHasVat(e.target.checked)} />
                                <label className="form-check-label" htmlFor="formvat">
                                    Has VAT
                                </label>
                                <input className="form-check-input mx-2" type="checkbox"
                                    id="formdiscount" checked={hasdiscount} onChange={(e) => setHasDiscount(e.target.checked)} />
                                <label className="form-check-label" htmlFor="formdiscount">
                                    Has Discount
                                </label>

                            </div>
                            <div className="col-lg-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-sm">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Description / Model</th>
                                                <th>Part Number</th>
                                                <th>Serial No</th>
                                                <th>Qty</th>
                                                <th>Unit Price</th>
                                                <th>{`Amount  (${currency})`}</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map((row) => (
                                                <tr key={row.id}>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={row.item}
                                                            onChange={(e) => handleInputChange(row.id, 'item', e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={row.partno}
                                                            onChange={(e) => handleInputChange(row.id, 'partno', e.target.value)} />
                                                    </td>
                                                    <td className="text-end">
                                                        <input type="text" className="form-control"
                                                            value={row.serialno}
                                                            onChange={(e) => handleInputChange(row.id, 'serialno', e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" className="form-control"
                                                            value={row.qty}
                                                            onChange={(e) => handleInputChange(row.id, 'qty', e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <CurrencyFormat
                                                            value={row.unitprice}
                                                            thousandSeparator={true}
                                                            className="form-control"
                                                            onValueChange={(values) => handleInputChange(row.id, 'unitprice', values.floatValue)}
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <CurrencyFormat
                                                            value={row.amt}
                                                            thousandSeparator={true}
                                                            className="form-control"
                                                            onValueChange={(values) => handleInputChange(row.id, 'amt', values.floatValue)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <ul className="list-unstyled hstack gap-1 mb-0">
                                                            <li onClick={() => deleteRow(row.id)} data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Delete" data-bs-original-title="Delete">
                                                                <a href="#jobDelete" className="btn btn-sm btn-soft-danger"><i className="mdi mdi-delete-outline"></i></a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="5" className="text-end"><strong>Sub Total Amount</strong></td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={subTotal}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        className="form-control"
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                            {hasdiscount && <tr>
                                                <td colSpan="5" className="border-0 text-end"><strong>Discount (%)</strong></td>
                                                <td className="text-end">
                                                    <input type="number" className="form-control" placeholder="" value={discount} onChange={(e) => setDiscount(parseFloat(e.target.value) || 0)} />
                                                </td>
                                            </tr>}
                                            <tr>
                                                <td colSpan="5" className="border-0 text-end"><strong>Net Total Amount</strong></td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={netTotal}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        className="form-control"
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                            {hasvat && <tr>
                                                <td colSpan="5" className="border-0 text-end"><strong>VAT 18%</strong></td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={vat}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        className="form-control"
                                                        disabled
                                                    />
                                                </td>
                                            </tr>}
                                            <tr>
                                                <td colSpan="5" className="text-end"><strong>Gross Total Amount</strong></td>
                                                <td className="text-end">
                                                    <CurrencyFormat
                                                        value={grossTotal}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        className="form-control"
                                                        disabled
                                                    />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <div className="float-end">
                                <button onClick={handleSubmit} className="btn btn-primary w-md waves-effect waves-light">
                                    {loading ? <FNSpinner /> : "Add Supply Details"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AddSupply;