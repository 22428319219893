
import Print from "./Print";
import image from "../../../assets/images/proformaInvoice.png";

const PrintProforma = ({ match }) => {

  
  const { id, j_id } = match.params;


  return (
    <div>
     <Print id={id} j_id={j_id} image={image}/>
    </div>
  );
};

export default PrintProforma;
