import { useEffect, useRef, useState } from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import CurrencyFormat from "react-currency-format";
import API from "../../../helpers/api";

import footerImage from "../../../assets/images/proformaFooter.png";
import { toast } from "react-toastify";
import { LucideDownload } from "lucide-react";

function Print({ image, id, j_id }) {
  const componentRef = useRef();
  

  const [proforma, setProforma] = useState();
  const [loading, setLoading] = useState();
  const [spareParts, setSpareParts] = useState();

  const loadProforma = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/proforma/${id}`);
      setProforma(res?.data.proforma);
      loadSpareParts();
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const loadSpareParts = async () => {
    try {
      const res = await API.get(`/jobcard/spare/${j_id}`);
      console.log("spare===", res?.data?.spareparts);
      setSpareParts(res?.data?.spareparts);
    } catch (error) {
      console.log("error", error);
      toast.error("Error loading spare parts");
    }
  };

  useEffect(() => {
    if (id) {
      loadProforma();
    }
  }, []);

  console.log("pro---", proforma);
  return (
    <>
      <div
        ref={componentRef}
        style={{ minHeight: "100vh", position: "relative" }}
        className="  min d-flex flex-column justify-content-between"
      >
        {" "}
        <div
          className=" d-flex justify-content-center mb-3 fixed-top"
          style={{ right: "-3%", top: "25%" }}
        >
          <div className="col-10">
            <div class="d-print-none">
              <div class="float-end">
                <ReactToPrint
                  trigger={() => (
                    <button class="btn btn-lg btn-warning   w-md waves-effect waves-light">
                      <LucideDownload /> Print Invoice
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid ">
          {/* <div className="col-md-6">
            {" "}
            <pre className="mt-4 p-3 bg-light rounded">
              {JSON.stringify(spareParts, null, 2)}
            </pre>
            <pre className="mt-4 p-3 bg-light rounded">
              {JSON.stringify(proforma, null, 2)}
            </pre>
          </div> */}

          <div className="row d-flex justify-content-center">
            <div className="col-11">
              <div className="card">
                <div className="card-body">
                  <div
                    className="mb-4"
                    style={{
                      width: "100%",
                      // height: "40vh",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={image}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>

                  <section className=" container-fluid">
                    <div className="row">
                      <div className="col-6 ">
                        {" "}
                        <h4>
                          M/S :{" "}
                          <span className="fw-bold">
                            {proforma?.jobcard?.vehicle
                              ? proforma?.jobcard?.vehicle?.customer.fullname
                              : ""}
                          </span>
                        </h4>
                      </div>
                      <div className="col-6 ">
                        <h5>
                          Address:{" "}
                          <span className="fw-bold">
                            {proforma?.jobcard?.vehicle?.customer
                              ? proforma?.jobcard?.vehicle?.customer?.address
                              : ""}
                          </span>
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 ">
                        <h5>
                          Date:{" "}
                          <span className="fw-bold">
                            {moment(proforma?.updatedAt).format("YYYY-MM-DD")}
                          </span>
                        </h5>
                      </div>
                      <div className="col-6 ">
                        <h5>
                          Invoice No:{" "}
                          <span className="fw-bold">{proforma?.id}</span>
                        </h5>
                      </div>
                      <div className="col-6 ">
                        <h5>
                          Number Plate:{" "}
                          <span className="fw-bold">   {proforma?.jobcard?.vehicle?.numberplate
                              ? proforma?.jobcard?.vehicle?.numberplate
                              : ""}</span>
                        </h5>
                      </div>
                      <div className="col-6 ">
                        <h5>
                          Vehicle Model:{" "}
                          <span className="fw-bold">   {proforma?.jobcard?.vehicle?.model?.name
                              ? proforma?.jobcard?.vehicle?.model?.name
                              : ""}</span>
                        </h5>
                      </div>
                      <div className="col-6 ">
                        <h5>
                          Vehicle Make:{" "}
                          <span className="fw-bold">   {proforma?.jobcard?.vehicle?.make?.name
                              ? proforma?.jobcard?.vehicle?.make?.name
                              : ""}</span>
                        </h5>
                      </div>
                    </div>
                  </section>
                  <section className="mt-4">
                    {/* <div class="py-2 mt-3">
                                      <h3 class="font-size-15 fw-bold">Job Card Items Worked On</h3>
                                  </div> */}
                    <div class="table-responsive">
                      <table class="table table-striped table-bordered border-secondary table-sm">
                        <thead className="text-white b">
                          <tr className="">
                            <th
                              className="text-white py-2"
                              style={{ backgroundColor: "#5988FF" }}
                              width="350"
                            >
                              Description / Model
                            </th>
                            <th
                              className="text-white py-2"
                              style={{ backgroundColor: "#5988FF" }}
                            >
                              Part No
                            </th>
                            <th
                              className="text-white py-2"
                              style={{ backgroundColor: "#5988FF" }}
                            >
                              Serial No
                            </th>
                            <th
                              className="text-white py-2"
                              style={{ backgroundColor: "#5988FF" }}
                            >
                              Qty
                            </th>
                            <th
                              className="text-white text-end py-2"
                              style={{ backgroundColor: "#5988FF" }}
                            >
                              Unit Price
                            </th>
                            <th
                              className="text-white text-end py-2"
                              style={{ backgroundColor: "#5988FF" }}
                            >
                              Ammount
                            </th>
                            {/* <th class="text-end">{`Amount  
                            (${supply.currency})
                            `}</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {spareParts &&
                            spareParts.map((item) => (
                              <tr key={item.id}>
                                <td>{item.partname}</td>
                                <td>{item.partno}</td>
                                <td>{item.id}</td>
                                <td>{item.qtyUsed}</td>
                                <td class="text-end">
                                  <CurrencyFormat
                                    value={item.unitPrice}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                </td>

                                <td class="text-end">
                                  <CurrencyFormat
                                    value={Number(item.qtyUsed)*Number(item.unitPrice)}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td colspan="5" class="text-end">
                              <strong>Sub Total Amount</strong>
                            </td>
                            <td class="text-end">
                              <CurrencyFormat
                                value={proforma?.subTotal}
                                // value="test"
                                displayType="text"
                                thousandSeparator
                              />
                            </td>
                          </tr>
                          <tr>
                            {proforma?.discount ? (
                              <>
                                <td colspan="5" class="border-0 text-end">
                                  <strong>Discount</strong>
                                </td>
                                <td class="text-end">
                                  <CurrencyFormat
                                    value={proforma?.discount}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                          <tr>
                            {proforma?.discount ? (
                              <>
                                <td colspan="5" class="border-0 text-end">
                                  <strong>Net Total Amount</strong>
                                </td>
                                <td class="text-end">
                                  <CurrencyFormat
                                    value={proforma?.netTotal}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                          <tr>
                            {proforma?.vat ? (
                              <>
                                <td colspan="5" class="border-0 text-end">
                                  <strong>VAT</strong>
                                </td>
                                <td class="text-end">
                                  <CurrencyFormat
                                    value={proforma?.vat}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                          <tr>
                            <td colspan="5" class="text-end">
                              <strong>{`Gross Total Amount (${proforma?.jobcard?.currency})`}</strong>
                            </td>
                            <td class="text-end">
                              <h4 class="m-0">
                                <CurrencyFormat
                                  value={proforma?.grossTotal}
                                  displayType="text"
                                  thousandSeparator
                                />
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                  {/* <section>
                                  <p>Notes to client</p>
                              </section> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Fragment>
          <div className="row d-flex justify-content-center" ref={componentRef}>
            <div className="col-10">
              <div className="card">
                <div className="card-body">
           
                  <div>
                    {proforma.jobcard ? (
                      <JobCardData id={proforma.jobcard.id} />
                    ) : (
                      <p>Loading</p>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <h4 className="card-title mb-4">Vehicle Spare Parts</h4>
                          <form>
                            <div className="table-responsive">
                              <table className="table table-striped table-sm">
                                <thead className="table-light">
                                  <tr>
                                    <th style={{ width: "50%" }}>Spare part</th>
                                    <th style={{ width: "8%" }}>Qty</th>
                                    <th style={{ width: "12%" }}>Unit Price</th>
                                    <th
                                      style={{ width: "12%" }}
                                    >{`Amount (${proforma?.jobcard?.currency})`}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {spareParts?.map((part) => (
                                    <tr key={part.id}>
                                      <td>{part.partname}</td>
                                      <td>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={part.qtyUsed}
                                          disabled
                                          // onChange={(e) =>
                                          //   handleInputChange(
                                          //     part.id,
                                          //     "qtyUsed",
                                          //     parseFloat(e.target.value)
                                          //   )
                                          // }
                                        />
                                      </td>
                                      <td>
                                        <CurrencyFormat
                                          value={part.unitPrice}
                                          thousandSeparator
                                          disabled={true}
                                          className="form-control"
                                          // onValueChange={(values) =>
                                          //   handleInputChange(
                                          //     part.id,
                                          //     "unitPrice",
                                          //     values.floatValue || 0
                                          //   )
                                          // }
                                        />
                                      </td>
                                      <td className="text-end">
                                        <CurrencyFormat
                                          disabled={true}
                                          value={
                                            part.qtyUsed * part.unitPrice || 0
                                          }
                                          thousandSeparator
                                          displayType="text"
                                          prefix={`${proforma?.jobcard?.currency} `}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                                <tfoot className="">
                                  <tr className="">
                                    <td colSpan={3} className="text-end">
                                      <strong>Sub Total Amount</strong>
                                    </td>
                                    <td className="text-end">
                                      <CurrencyFormat
                                        value={proforma?.subTotal || 0}
                                        displayType="text"
                                        thousandSeparator
                                        prefix={`${proforma?.jobcard?.currency} `}
                                      />
                                    </td>
                                  </tr>
  
                                  <tr>
                                    <td colSpan={3} className="text-end">
                                      <strong>Discount (%)</strong>
                                    </td>
                                    <td className="text-end">
                                      <input
                                        type="number"
                                        disabled
                                        className="form-control"
                                        value={proforma?.discount || 0}
                                        // onChange={(e) =>
                                        //   setDiscount(
                                        //     parseFloat(e.target.value) || 0
                                        //   )
                                        // }
                                      />
                                    </td>
                                  </tr>
  
                                  <tr>
                                    <td colSpan={3} className="text-end">
                                      <strong>Net Total Amount</strong>
                                    </td>
                                    <td className="text-end">
                                      <CurrencyFormat
                                        value={proforma?.netTotal || 0}
                                        displayType="text"
                                        disabled={true}
                                        thousandSeparator
                                        prefix={`${proforma?.jobcard?.currency} `}
                                      />
                                    </td>
                                  </tr>
  
                                  <tr>
                                    <td colSpan={3} className="text-end">
                                      <strong>VAT 18%</strong>
                                    </td>
                                    <td className="text-end">
                                      <CurrencyFormat
                                        value={proforma?.vat || 0}
                                        displayType="text"
                                        disabled={true}
                                        thousandSeparator
                                        prefix={`${proforma?.jobcard?.currency} `}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan={3} className="text-end">
                                      <strong>Gross Total Amount</strong>
                                    </td>
                                    <td className="text-end">
                                      <CurrencyFormat
                                        value={proforma?.grossTotal || 0}
                                        displayType="text"
                                        disabled={true}
                                        thousandSeparator
                                        prefix={`${proforma?.jobcard?.currency} `}
                                      />
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer>
                    <ul className="list-unstyled">
                      <li>STANBIC BANK : 9030017839968 - GARDEN CITY.</li>
                      <li>AC NAME: SSEJP ENGINEERING AND CONSULTS-SMC LTD</li>
                      <li> SWIFT: SBICUGKX</li>
                    </ul>
                  </footer>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center mb-3">
            <div className="col-10">
              <div class="d-print-none">
                <div class="float-end">
                  <ReactToPrint
                    trigger={() => (
                      <button class="btn btn-warning w-md waves-effect waves-light">
                        Print Vehicle Proforma
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                </div>
              </div>
            </div>
          </div>
        </Fragment> */}
        <div
          className="mt-3 container-fluid"
          style={{
            width: "100%",
            height: "200px",
            overflow: "hidden",
          }}
        >
          <img
            src={footerImage}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "bottom",
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Print;
