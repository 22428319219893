import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import { Link, useHistory } from "react-router-dom";
import API from "../../../helpers/api";
import FNSpinner from "../../../components/FNSpinner";
import FNTable from "../../../components/FNTable1";

const Proforma = () => {
  const [proforma, setProforma] = useState([]);
  const [singleproforma, setSingleProforma] = useState();
  const [loading, setLoading] = useState([]);

  const history = useHistory();

  const loadProforma = async () => {
    setLoading(true);
    try {
      const res = await API.get("/proforma");

      const formattedJobs = res.data.proforma.map((proforma) => ({
        ...proforma,
        createdAt: moment(proforma.createdAt).format("YYYY-MM-DD"),
        subTotal: (
          <CurrencyFormat
            value={proforma.subTotal}
            displayType="text"
            thousandSeparator
          />
        ),
        netTotal: (
          <CurrencyFormat
            value={proforma.netTotal}
            displayType="text"
            thousandSeparator
          />
        ),
        vat: (
          <CurrencyFormat
            value={proforma.vat}
            displayType="text"
            thousandSeparator
          />
        ),
        grossTotal: (
          <CurrencyFormat
            value={proforma.grossTotal}
            displayType="text"
            thousandSeparator
          />
        ),
      }));
      setProforma(formattedJobs);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const loadSingleProforma = async (id) => {
    setLoading(true);
    try {
      const res = await API.get(`/proforma/${id}`);

      setSingleProforma(res?.data?.proforma);
      return res?.data?.proforma;
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const printProforma = async (id) => {
    const prfm = await loadSingleProforma(id);
    history.push(`/proforma/view/${id}/${prfm?.jobcard?.id}`);
  };

  const printInvoice = async (id) => {
    const prfm = await loadSingleProforma(id);
    history.push(`/invoice/print/${id}/${prfm?.jobcard?.id}`);
  };

  const handleUpdate = async (id) => {
    const prfm = await loadSingleProforma(id);
    history.push(`/proforma/edit/${id}/${prfm?.jobcard?.id}`);
  };

  useEffect(() => {
    loadProforma();
  }, []);

  const tableColumns = [
    { key: "id", label: "Proforma No" },
    { key: "createdAt", label: "Proforma Date" },
    { key: "subTotal", label: "Sub Total" },
    { key: "discount", label: "Discount" },
    { key: "netTotal", label: "Net Total" },
    { key: "vat", label: "VAT" },
    { key: "grossTotal", label: "Gross Total" },
  ];
  console.log("proforma", proforma);
  return (
    <Fragment>
      <div class="row">
        <div class="col-12">
          <div class="page d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Proforma Invoices</h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <Link to="/ict/proforma">Vehicles</Link>
                </li>
                <li class="breadcrumb-item active">Proforma Invoices</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <FNSpinner />
      ) : (
        <>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row mb-2">
                        <div class="col-sm-4">
                          <div class="search-box me-2 mb-2 d-inline-block">
                            <div class="position-relative">
                              <input
                                type="text"
                                class="form-control"
                                id="searchTableList"
                                placeholder="Search..."
                              />
                              <i class="bx bx-search-alt search-icon"></i>
                            </div>
                          </div>
                        </div>
                        {/* <div class="col-sm-8">
                                                    <div class="text-sm-end">
                                                        <button type="submit" class="btn btn-primary waves-effect waves-light" onClick={handleShow}>Add Garage Job Card</button>
                                                    </div>
                                                </div> */}
                      </div>
                      <FNTable
                        columns={tableColumns}
                        data={proforma}
                        onViewDetails={printProforma}
                        handleEdit={printInvoice}
                        handleUpdate={handleUpdate}
                        title1="Print Proforma"
                        title2="Print Invoice"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default Proforma;
