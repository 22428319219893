import React from 'react'
import GarageBar from './GarageBar'

const TopNavBar = () => {

    return (
        <div class="topnav">
            <div class="container-fluid">
                <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
                    <div class="collapse navbar-collapse" id="topnav-menu-content">
                        <GarageBar />
                    </div>
                </nav>
            </div>
        </div>


    )
}

export default TopNavBar