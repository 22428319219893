import React, { useState } from 'react';
import { toast } from "react-toastify";
import API from "../../../helpers/api";
import LoadSpinner from "../../../components/FNSpinner";

const AddDiscount = ({ totalAmt, close, id }) => {
    const [loading, setLoading] = useState(false);
    const [subTotal, setSubTotal] = useState(totalAmt);
    const [discount, setDiscount] = useState('');
    const [grossTotal, setGrossTotal] = useState('');
    const [vat, setVat] = useState('');
    const [netTotal, setNetTotal] = useState('');
    const [proformaNo, setProformaNo] = useState('');
    const [proformaDate, setProformaDate] = useState('');

    const calculateValues = () => {
        const subTotalNum = parseFloat(subTotal);
        const discountNum = parseFloat(discount);

        if (isNaN(subTotalNum) || isNaN(discountNum)) {
            // alert("Please enter valid numbers for Sub Total and Discount");
            toast.error(`Discount Amount Can't be Empty`);
            return;
        }

        const netTotal = subTotalNum - discountNum;
        const vat = netTotal * 0.18;
        const grossTotal = netTotal + vat;

        setGrossTotal(grossTotal);
        setVat(vat);
        setNetTotal(netTotal);
    };

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();

        const data = {
            subTotal,
            discount,
            grossTotal,
            vat,
            netTotal,
            proformaNo,
            proformaDate,
            jobcardId: id
        }

        try {
            const res = await API.post(`/proforma`, data);
            console.log(res)
            toast.success(`Proforma Generated Successful`);
            close();
            setLoading(false)
        } catch (error) {
            setLoading(false);
            toast.error(`Proforma Generation Failed`);
        }
    };

    return (
        <>
            <div className='card'>
                <div className='card-body'>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Proforma Number</label>
                                <input type="text" class="form-control" placeholder=""
                                    value={proformaNo}
                                    onChange={(e) => setProformaNo(e.target.value)}
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Proforma Date</label>
                                <input type="date" class="form-control" placeholder=""
                                    value={proformaDate}
                                    onChange={(e) => setProformaDate(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Sub Total Amount</label>
                                <input type="text" class="form-control" placeholder=""
                                    value={subTotal}
                                    onChange={(e) => setSubTotal(e.target.value)}
                                    disabled
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Discount</label>
                                <input type="text" class="form-control" placeholder=""
                                    value={discount}
                                    onChange={(e) => setDiscount(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">Net Amount</label>
                                <input type="text" class="form-control" placeholder=""
                                    value={netTotal}
                                    disabled />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label class="form-label">VAT (18%)</label>
                                <input type="text" class="form-control" placeholder=""
                                    value={vat} disabled />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="mb-3">
                                <label class="form-label">Gross Total Amount</label>
                                <input type="text" class="form-control" placeholder=""
                                    value={grossTotal}
                                    disabled />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <button class="btn btn-secondary waves-effect waves-light me-1" onClick={calculateValues}>Calculate Values</button>
                <button class="btn btn-primary waves-effect waves-light" type="submit" onClick={handleSubmit}>
                    {loading ? (
                        <LoadSpinner />
                    ) : (
                        'Add Proforma'
                    )}
                </button>
            </div>
        </>
    )
}

export default AddDiscount