"use client"

import { useEffect, useState } from "react"
import API from "../../helpers/api"
import JobcardHead from "../../assets/images/jobCard2.png"
import { Pencil, Calendar, User, Briefcase } from "lucide-react"
import DetailsTable from "./DetailsTable"

function JobCardData({ id, image }) {
  const [jobcard, setJobCard] = useState()
  const [loading, setLoading] = useState(false)
  const [vehicle, setVehicle] = useState({})
  const [spareParts, setSparePart] = useState([])

  const loadJobCard = async () => {
    setLoading(true)
    try {
      const res = await API.get(`/jobcard/${id}`)
      await fetchVehicleDetails(res?.data.job.vehicleId)
      await loadSpareParts()
      setJobCard(res?.data.job)
      setLoading(false)
    } catch (error) {
      console.log("error", error)
      setLoading(false)
    }
  }

  const fetchVehicleDetails = async (id) => {
    try {
      const res = await API.get(`/vehicle/${id}`)
      setVehicle(res?.data.vehicle)
    } catch (error) {
      console.log("Error fetching vehicle details", error)
    }
  }

  const loadSpareParts = async () => {
    try {
      const res = await API.get(`/jobcard/spare/${id}`)
      setSparePart(res?.data?.spareparts)
    } catch (error) {
      console.log("error", error)
    }
  }
  const vehicleData = [
    ["Customer Name", vehicle.customer?.fullname, "Address", vehicle.customer?.address],
    ["Contact Person", vehicle.customer?.tin, "Telephone Number", vehicle.make?.name],
    ["Make", vehicle.make?.name, "Model", vehicle.model?.name],
    ["Engine Number", vehicle.engineno, "Engine Size", vehicle.enginesize],
    ["Chassis Number", vehicle.chassisno, "YOM", vehicle.year],
    ["Mileage", vehicle.mileage, "Transmission", vehicle.transmission],
  ];
  const jobcardData = [
    ["Driver or Customer Name", jobcard?.driverOrCustomerName, "Signature", jobcard?.signature],
    [ "Workshop Manager", jobcard?.workshopManager, "Testor Signature", jobcard?.testorSignature,],
    ["Date Closed", jobcard?.dateClosed, "Date Tested", jobcard?.dateTested],
  ];

  useEffect(() => {
    if (id) {
      loadJobCard()
    }
  }, []) // Added loadJobCard as a dependency

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="d-sm-flex mb-4">
            <div style={{ width: "100%" }}>
              <img
                src={image || JobcardHead}
                alt=""
                style={{
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4">
                  <div className="mb-3">
                    <h5>({jobcard?.vehicle?.numberplate})</h5>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <DetailsTable tableData={vehicleData}/>
                    {/* <table className="table table-bordered border-secondary table-sm">
                      <tbody>
                        <tr>
                          <th scope="row" className="bg-primary text-white">
                            Customer Name
                          </th>
                          <td>{vehicle.customer ? vehicle.customer.fullname : ""}</td>
                          <th scope="row" className="bg-primary text-white">
                            Address
                          </th>
                          <td>{vehicle.customer ? vehicle.customer.address : ""}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="bg-primary text-white">
                            Contact Person
                          </th>
                          <td>{vehicle.customer ? vehicle.customer.tin : ""}</td>
                          <th scope="row" className="bg-primary text-white">
                            Telephone Number
                          </th>
                          <td>{vehicle.make ? vehicle.make.name : ""}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="bg-primary text-white">
                            Make
                          </th>
                          <td>{vehicle.make ? vehicle.make.name : ""}</td>
                          <th scope="row" className="bg-primary text-white">
                            Model
                          </th>
                          <td>{vehicle.model ? vehicle.model.name : ""}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="bg-primary text-white">
                            Engine Number
                          </th>
                          <td>{vehicle ? vehicle.engineno : ""}</td>
                          <th scope="row" className="bg-primary text-white">
                            Engine Size
                          </th>
                          <td>{vehicle ? vehicle.enginesize : ""}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="bg-primary text-white">
                            Chassis Number
                          </th>
                          <td>{vehicle ? vehicle.chassisno : ""}</td>
                          <th scope="row" className="bg-primary text-white">
                            YOM
                          </th>
                          <td>{vehicle ? vehicle.year : ""}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="bg-primary text-white">
                            Mileage
                          </th>
                          <td>{vehicle ? vehicle.mileage : ""}</td>
                          <th scope="row" className="bg-primary text-white">
                            Transmission
                          </th>
                          <td>{vehicle ? vehicle.transmission : ""}</td>
                        </tr>
                      </tbody>
                    </table> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card bg-light p-3 text-secondary mt-3">
            <p>
              <strong className="">NOTE:</strong> While we strive to provide the best service possible, please be
              advised that any unforeseen issues that may arise during the maintenance of your vehicle are not the
              responsibility of the service provider. We recommend discussing any concerns or additional services with
              our staff prior to commencing work on your vehicle. Disclaimer: I hereby certify that I have legal right
              to authorize repairs, road test, etc. I also hereby confirm that I shall not hold SSEJP E&C, its
              Directors, Employees and representatives liable for any damage beyond their control which may arise during
              the repairs. By authorizing the maintenance work, you acknowledge and accept these terms.
            </p>
          </div>

        
          <div className="card mt-3">
            <div className="card-body">
              <h4 className="py-3">I did not leave any personal effects in my vehicle</h4>
              <DetailsTable tableData={jobcardData}/>
              {/* <div className="row g-4 mb-4">
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="driverOrCustomerName"
                      placeholder={jobcard?.driverOrCustomerName}
                      value={jobcard?.driverOrCustomerName}
                      disabled
                    />
                    <label className="mb-3" htmlFor="driverOrCustomerName">
                      <User className="me-2" size={18} />
                      Driver or Customer Name
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="signature"
                      placeholder={jobcard?.signature}
                      value={jobcard?.signature}
                      disabled
                    />
                    <label className="mb-3" htmlFor="signature">
                      <Pencil className="me-2" size={18} />
                      Signature
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="testorSignature"
                      placeholder={jobcard?.testorSignature}
                      value={jobcard?.testorSignature}
                      disabled
                    />
                    <label className="mb-3" htmlFor="testorSignature">
                      <Pencil className="me-2" size={18} />
                      Testor Signature
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="workshopManager"
                      placeholder={jobcard?.workshopManager}
                      value={jobcard?.workshopManager}
                      disabled
                    />
                    <label className="mb-3" htmlFor="workshopManager">
                      <Briefcase className="me-2" size={18} />
                      Workshop Manager
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span className="input-group-text">
                      <Calendar size={18} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="dateClosed"
                      placeholder="Date Closed"
                      value={jobcard?.dateClosed || ""}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <span className="input-group-text">
                      <Calendar size={18} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="dateTested"
                      placeholder="Date of Tested"
                      value={jobcard?.dateTested || ""}
                      disabled
                    />
                  </div>
                </div>
              </div> */}

              <div className="row">
                <div className="col-12">
                  <div className="row bg-light text-secondary mb-2 py-3">
                    <div className="col-6">
                      <div className="mb-3 row">
                        <div className="col-12">
                          <div className="d-flex flex-row me-3">
                            <label className="me-2">Tools:</label>
                            <div className="form-check form-checkbox-outline form-check-primary mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="jack"
                                checked={jobcard?.jack}
                                disabled
                              />
                              <label className="form-check-label" htmlFor="jack">
                                Jack
                              </label>
                            </div>
                            <div className="form-check form-checkbox-outline form-check-success">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="wheelspanner"
                                checked={jobcard?.wheelSpanner}
                                disabled
                              />
                              <label className="form-check-label" htmlFor="wheelspanner">
                                Wheelspanner
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="mb-3 row">
                        <div className="col-12">
                          <div className="d-flex flex-row me-5">
                            <label className="me-2">Windscreen Damage:</label>
                            <div className="form-check form-checkbox-outline form-check-primary">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="windscreenDamageYes"
                                checked={jobcard?.windscreenDamage === true}
                                disabled
                              />
                              <label className="form-check-label" htmlFor="windscreenDamageYes">
                                Yes
                              </label>
                            </div>
                            <div className="form-check form-checkbox-outline form-check-primary">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="windscreenDamageNo"
                                checked={jobcard?.windscreenDamage === false}
                                disabled
                              />
                              <label className="form-check-label" htmlFor="windscreenDamageNo">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3 row">
                        <div className="col-12">
                          <div className="d-flex flex-row me-5">
                            <label className="me-2">Other Damage:</label>
                            <div className="form-check form-checkbox-outline form-check-primary">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="otherDamageYes"
                                checked={jobcard?.otherDamage === true}
                                disabled
                              />
                              <label className="form-check-label" htmlFor="otherDamageYes">
                                Yes
                              </label>
                            </div>
                            <div className="form-check form-checkbox-outline form-check-primary">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="otherDamageNo"
                                checked={jobcard?.otherDamage === false}
                                disabled
                              />
                              <label className="form-check-label" htmlFor="otherDamageNo">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-3 row">
                        <div className="col-12">
                          <div className="d-flex flex-row">
                            <label className="me-2">Payment Method:</label>
                            <span>{jobcard?.paymentMethod}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="row">
                    <div className="col-3">
                      <label htmlFor="technicianName" className="form-label">
                        Technician's Name
                      </label>
                    </div>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        id="technicianName"
                        placeholder={jobcard?.technicianName}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="mb-3 row">
                    <div className="col-3">
                      <label htmlFor="dateIn" className="form-label">
                        Date In
                      </label>
                    </div>
                    <div className="col-9">
                      <input type="text" className="form-control" id="dateIn" placeholder={jobcard?.dateIn} disabled />
                    </div>
                  </div>
                </div>

                <div className="col-4">
                  <div className="mb-3 row">
                    <div className="col-3">
                      <label htmlFor="dateOut" className="form-label">
                        Date Out:
                      </label>
                    </div>
                    <div className="col-9">
                      <input
                        type="text"
                        className="form-control"
                        id="dateOut"
                        placeholder={jobcard?.dateOut}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default JobCardData








// import React, { useEffect, useState } from "react";
// import Select from "react-select/base";
// import API from "../../helpers/api";
// import JobcardHead from "../../assets/images/jobCard2.png";

// function JobCardData({ id, image }) {
//   const [jobcard, setJobCard] = useState();
//   const [loading, setLoading] = useState(false);
//   const [vehicle, setVehicle] = useState(false);
//   const [spareParts, setSparePart] = useState([]);

//   const loadJobCard = async () => {
//     setLoading(true);
//     try {
//       const res = await API.get(`/jobcard/${id}`);
//       await fetchVehicleDetails(res?.data.job.vehicleId);
//       await loadSpareParts();
//       setJobCard(res?.data.job);

//       setLoading(false);
//     } catch (error) {
//       console.log("error", error);
//       setLoading(false);
//     }
//   };

//   const fetchVehicleDetails = async (id) => {
//     try {
//       const res = await API.get(`/vehicle/${id}`);
//       console.log(res);
//       setVehicle(res?.data.vehicle);
//     } catch (error) {
//       console.log("Error fetching vehicle details", error);
//     }
//   };

//   const loadSpareParts = async () => {
//     // setLoading(true);
//     try {
//       const res = await API.get(`/jobcard/spare/${id}`);
//       //   console.log("spare====", res?.data?.spareparts);
//       setSparePart(res?.data?.spareparts);
//       // setLoading(false);
//     } catch (error) {
//       console.log("error", error);
//       // setLoading(false);
//     }
//   };

//   useEffect(() => {
//     if (id) {
//       loadJobCard();
//     }
//   }, []);
//   console.log("==---===---", jobcard);
//   return (
//     <>
//       <div className="row">
//         <div className="col-12">
//           <div className="d-sm-flex mb-4">
//             <div style={{ width: "100%"}}>
//               {image ? (
//                 <img
//                   src={image}
//                   alt=""
//                   style={{
//                     width: "100%",
//                     // height: "200px",
//                     objectFit: "contain",
//                   }}
//                 />
//               ) : (
//                 <img
//                   src={JobcardHead}
//                   alt=""
//                   style={{
//                     width: "100%",
//                     // height: "200px",
//                     objectFit: "contain",
//                   }}
//                 />
//               )}
//             </div>
//           </div>
//         </div>

//         <div className="col-md-12">
//           {" "}
//           <div className="row">
//             <div className="col-12">
//               <div className="d-sm-flex align-items-center justify-content-between mb-3">
//                 <div className="page-title-right">
//                   {/* <ol className="breadcrumb m-0">
//                     <li className="breadcrumb-item">
//                       <a href="javascript: void(0);">Garage</a>
//                     </li>
//                     <li className="breadcrumb-item active">Job Card</li>
//                   </ol> */}
//                 </div>
//               </div>
//             </div>
//           </div>
    
//           <div className="card">
//             <div className="card-body">
//             <div class="row">
//             <div class="col-lg-4">
//               <div class="mb-3">
//               <h5>
//               {/* Vehicle Number Plate */}
//                   ({jobcard?.vehicle?.numberplate})
//                 </h5>
//                 {/* <label for="kycselectcity-input" class="form-label">
//                   Vehicle Number Plate ({jobcard?.vehicle?.numberplate})
//                 </label> */}
//                 {/* <Select
//                   // defaultValue={vehicleId}
//                   // onChange={handleChangeVehicle}
//                   // options={vehicles.map((vehicle) => ({
//                   //   value: vehicle.id,
//                   //   label: vehicle.numberplate,
//                   // }))}
//                   placeholder={jobcard?.vehicle?.numberplate}
//                 /> */}
//               </div>
//             </div>
//             {/* <div class="col-lg-3">
//               <div class="mb-3">
//                 <label for="kycselectcity-input" class="form-label">
//                   Currency
//                 </label>
//                 <select
//                   class="form-select"
//                   aria-label="Select example"
//                   value={jobcard?.currency}
//                   disabled
//                   // onChange={(e) => setCurrency(e.target.value)}
//                 >
//                   <option>Select Currency </option>
//                   <option>UGX</option>
//                   <option>USD</option>
//                   <option>EURO</option>
//                   <option>KES</option>
//                 </select>
//               </div>
//             </div> */}
//           </div>
//               <div className="row">
//                 <div className="col-12">
//                   <div class="table-responsive">
//                     {/* <h5 className="">Vehicle Details</h5> */}
//                     <table class="table table-bordered border-secondary  table-sm">
//                       <tbody>
//                         <tr>
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Customer Name
//                           </th>
//                           <td>
//                             {vehicle.customer ? vehicle.customer.fullname : ""}
//                           </td>
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Address
//                           </th>
//                           <td>
//                             {vehicle.customer ? vehicle.customer.address : ""}
//                           </td>
//                         </tr>
//                         <tr>
//                           {" "}
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Contact Person
//                           </th>
//                           <td>
//                             {vehicle.customer ? vehicle.customer.tin : ""}
//                           </td>
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Telephone Number
//                           </th>
//                           <td>{vehicle.make ? vehicle.make.name : ""}</td>
//                         </tr>
//                         <tr>
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Model
//                           </th>
//                           <td>{vehicle.make ? vehicle.make.name : ""}</td>
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Model
//                           </th>
//                           <td>{vehicle.model ? vehicle.model.name : ""}</td>
//                         </tr>

//                         <tr>
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Engine Number
//                           </th>
//                           <td>{vehicle ? vehicle.engineno : ""}</td>
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Engine Size
//                           </th>
//                           <td>{vehicle ? vehicle.enginesize : ""}</td>
//                         </tr>
//                         <tr>
//                           {" "}
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Chassis Number
//                           </th>
//                           <td>{vehicle ? vehicle.chassisno : ""}</td>
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             YOM
//                           </th>
//                           <td>{vehicle ? vehicle.year : ""}</td>
//                         </tr>
//                         <tr>
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Mileage
//                           </th>
//                           <td>{vehicle ? vehicle.mileage : ""}</td>
//                           <th
//                             scope="row"
//                             style={{
//                               backgroundColor: "#598Bff",
//                               // maxWidth: "20px",
//                             }}
//                           >
//                             Transmission
//                           </th>
//                           <td>{vehicle ? vehicle.transmission : ""}</td>
//                         </tr>
//                       </tbody>
//                     </table>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="card bg-secondary bg-opacity-10 p-3  text-secondary">
//                   <p>
//                     <strong className="">NOTE:</strong> While we strive to
//                     provide the best service possible, please be advised that
//                     any unforeseen issues that may arise during the maintenance
//                     of your vehicle are not the responsibility of the service
//                     provider. We recommend discussing any concerns or additional
//                     services with our staff prior to commencing work on your
//                     vehicle. Disclaimer: I hereby certify that I have legal
//                     right to authorize repairs, road test, etc. I also hereby
//                     confirm that I shall not hold SSEJP E&C, its Directors,
//                     Employees and representatives liable for any damage beyond
//                     their control which may arise during the repairs. By
//                     authorizing the maintenance work, you acknowledge and accept
//                     these terms.
//                   </p>
//                 </div>
//           <div className="card">
//             <div className="card-body">
//               <h4 className="py-3">
//                 I did not leave any personal effects in my vehicle
//               </h4>
//               <div class="row">
//                 <div class="col-6">
//                   <div class="mb-3 row">
//                     <div className="col-3">
//                       <label for="kycselectcity-input" class="form-label ">
//                         Driver or Customer Name
//                       </label>
//                     </div>
//                     <div className="col-6">
//                       {" "}
//                       <input
//                         type="text"
//                         class="form-control"
//                         placeholder={jobcard?.driverOrCustomerName}
//                         // onChange={(e) =>
//                         //   setDriverOrCustomerName(e.target.value)
//                         // }
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div class="col-6">
//                   <div class="mb-3 row">
//                     <div className="col-3">
//                       {" "}
//                       <label for="kycselectcity-input" class="form-label ">
//                         Signature
//                       </label>
//                     </div>
//                     <div className="col-6">
//                       {" "}
//                       <input
//                         type="text"
//                         class="form-control"
//                         placeholder={jobcard?.signature}
//                         // value={jobcard?.signature}
//                         // onChange={(e) => setSignature(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div class="col-6">
//                   <div class="mb-2 row">
//                     <div className="col-3">
//                       {" "}
//                       <label for="kycselectcity-input" class="form-label">
//                         Testor Signature
//                       </label>
//                     </div>
//                     <div className="col-6">
//                       {" "}
//                       <input
//                         type="text"
//                         class="form-control"
//                         placeholder={jobcard?.testorSignature}
//                         // value={jobcard?.testorSignature}
//                         // onChange={(e) =>
//                         //   setTestorSignature(e.target.value)
//                         // }
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div class="col-6">
//                   <div class="mb-3 row">
//                     <div className="col-3">
//                       <label for="kycselectcity-input" class="form-label ">
//                         Workshop Manager
//                       </label>
//                     </div>
//                     <div className="col-6">
//                       {" "}
//                       <input
//                         type="text"
//                         class="form-control"
//                         placeholder={jobcard?.workshopManager}
//                         // value={jobcard?.workshopManager}
//                         // onChange={(e) =>
//                         //   setWorkshopManager(e.target.value)
//                         // }
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div class="col-6">
//                   <div class="mb-3 row">
//                     <div className="col-3">
//                       {" "}
//                       <label for="kycselectcity-input" class="form-label ">
//                         Date Closed
//                       </label>
//                     </div>
//                     <div className="col-6">
//                       {" "}
//                       <input
//                         type="text"
//                         class="form-control"
//                         placeholder={jobcard?.dateClosed}
//                         // value={jobcard?.dateClosed}
//                         // onChange={(e) => setDateClosed(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div class="col-6">
//                   <div class="mb-3 row">
//                     <div className="col-3">
//                       {" "}
//                       <label for="kycselectcity-input" class="form-label ">
//                         Date of Tested
//                       </label>
//                     </div>
//                     <div className="col-6">
//                       {" "}
//                       <input
//                         type="text"
//                         class="form-control"
//                         placeholder={jobcard?.dateTested}
//                         // value={jobcard?.dateTested}
//                         // onChange={(e) => setDateTested(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                 </div>

               
//               </div>
//               <div className="row">
//               <div className="col-12">
//                   <div className="row bg-secondary bg-opacity-10 text-secondary mb-2 py-3">
//                     <div class="col-6">
//                       <div class="mb-3 row">
//                         <div className="col-12">
//                           <div className="d-flex flex-row me-3">
//                             <label className="me-2">Tools:</label>
//                             <div className="form-check form-checkbox-outline form-check-primary mb-2">
//                               <input
//                                 className="form-check-input"
//                                 type="checkbox"
//                                 id="jack"
//                                 checked={jobcard?.jack}
//                                 // onChange={handleCheckboxChange}
//                               />
//                               <label
//                                 className="form-check-label"
//                                 htmlFor="jack"
//                               >
//                                 Jack
//                               </label>
//                             </div>
//                             <div className="form-check form-checkbox-outline form-check-success">
//                               <input
//                                 className="form-check-input mx-1"
//                                 type="checkbox"
//                                 id="wheelspanner"
//                                 checked={jobcard?.wheelSpanner} // Use checked to bind the value properly
//                                 // onChange={
//                                 //   () =>
//                                 //     setItems({
//                                 //       ...items,
//                                 //       wheelSpanner: !items.wheelSpanner,
//                                 //     }) // Toggles the value
//                                 // }
//                               />

//                               <label
//                                 className="form-check-label"
//                                 htmlFor="wheelspanner"
//                               >
//                                 Wheelspanner
//                               </label>
//                             </div>
//                           </div>

//                           {/* Fuel Group */}
//                         </div>
//                       </div>
//                     </div>

//                     <div class="col-6">
//                       <div class="mb-3 row">
//                         <div className="col-12">
//                           {" "}
//                           <div className="d-flex flex-row me-5">
//                             <label className="me-2">Windscreen Damage:</label>
//                             <div className="form-check form-checkbox-outline form-check-primary">
//                               <input
//                                 className="form-check-input mx-1"
//                                 type="checkbox"
//                                 id="yes"
//                                 checked={jobcard?.windscreenDamage}
//                                 // onChange={handleCheckboxChange}
//                               />
//                               <label className="form-check-label" htmlFor="yes">
//                                 Yes
//                               </label>
//                             </div>
//                             <div className="form-check form-checkbox-outline form-check-primary">
//                               <input
//                                 className="form-check-input mx-1"
//                                 type="checkbox"
//                                 id="no"
//                                 checked={jobcard?.windscreenDamage}
//                                 // onChange={handleCheckboxChange}
//                               />
//                               <label className="form-check-label" htmlFor="no">
//                                 No
//                               </label>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div class="col-6">
//                       <div class="mb-3 row">
//                         <div className="col-12">
//                           {" "}
//                           <div className="d-flex flex-row me-5">
//                             <label className="me-2">Other Damage:</label>
//                             <div className="form-check form-checkbox-outline form-check-primary">
//                               <input
//                                 className="form-check-input mx-1"
//                                 type="checkbox"
//                                 id="yes"
//                                 // checked={items.yes}
//                                 // onChange={handleCheckboxChange}
//                               />
//                               <label className="form-check-label" htmlFor="yes">
//                                 Yes
//                               </label>
//                             </div>
//                             <div className="form-check form-checkbox-outline form-check-primary">
//                               <input
//                                 className="form-check-input mx-1"
//                                 type="checkbox"
//                                 id="no"
//                                 // checked={items.no}
//                                 // onChange={handleCheckboxChange}
//                               />
//                               <label className="form-check-label" htmlFor="no">
//                                 No
//                               </label>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                     <div class="col-6">
//                       <div class="mb-3 row ">
//                         <div className="col-12">
//                           {/* Payment Method Group */}
//                           <div className="d-flex flex-row">
//                             <label className="me-2">Payment Method:</label>

//                             {jobcard?.paymentMethod}
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div class="col-4">
//                   <div class=" row">
//                     <div className="col-3">
//                       <label for="kycselectcity-input" class="form-label ">
//                         Technician's Name
//                       </label>
//                     </div>
//                     <div className="col-6">
//                       {" "}
//                       <input
//                         type="text"
//                         class="form-control"
//                         placeholder={jobcard?.technicianName}
//                         // value={technicianName}
//                         // onChange={(e) => setTechName(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                 </div>

//                 <div class="col-4">
//                   <div class="mb-3 row">
//                     <div className="col-3">
//                       <label for="kycselectcity-input" class="form-label ">
//                         Date In
//                       </label>
//                     </div>
//                     <div className="col-6">
//                       {" "}
//                       <input
//                         type="text"
//                         class="form-control"
//                         placeholder={jobcard?.dateIn}
//                         // value={jobcard?.dateIn}
//                         // onChange={(e) => setDateIn(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                 </div>

//                 <div class="col-4">
//                   <div class="mb-3 row">
//                     <div className="col-3">
//                       <label for="kycselectcity-input" class="form-label ">
//                         Date Out:
//                       </label>
//                     </div>
//                     <div className="col-6">
//                       {" "}
//                       <input
//                         type="text"
//                         class="form-control"
//                         placeholder={jobcard?.dateOut}
//                         // value={jobcard?.dateOut}
//                         // onChange={(e) => setDateOut(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default JobCardData;
