import React, { Fragment } from "react";
import JobCardData from "../../../components/JobCardData/JobCardData";

import jobCardImage from "../../../assets/images/proformaInvoice.png";
import ProformaSpare from "./ProformaSpare";

const EditProforma = ({ match }) => {
  const { j_id, p_id } = match.params;

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <h4 className="mb-sm-0 font-size-18">
            Create Proforma Invoice for Job Card: #{j_id}
          </h4>
          <JobCardData id={j_id} image={jobCardImage} />
          <ProformaSpare id={j_id} p_id={p_id} showFooter={true} />
        </div>
      </div>
    </Fragment>
  );
};

export default EditProforma;
