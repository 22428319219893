import { toast } from "react-toastify";
import FNSpinner from "../../../../components/FNSpinner";
import API from "../../../../helpers/api";
import { useEffect, useState } from "react";
import moment from "moment";

const AddSpareStore = ({ close, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    partname: "",
    partno: "",
    categoryId: "",
    measure: "pcs",
    unitPrice: 0,
    qty: 0,
    qtyUsed: 0,
    qtyAvailable: 0,
  });

  const [sparePartCategory, setSpareCategory] = useState([]);

  const loadCategories = async () => {
    try {
      const res = await API.get(`/sparecategory`);
      const sparecategory = res?.data.sparecategory.map((spare) => ({
        ...spare,
        createdAt: moment(spare.createdAt).format("YYYY-MM-DD"),
      }));

      setSpareCategory(sparecategory);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await API.post("/sparestore", formData);
      console.log(response);
      setLoading(false);
      close();
      refresh();
      toast.success(`Spare Part Has Been Added Successfully`);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error("Error while Adding Spare Part");
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <>
      <div className="card custom-card">
        <div className="card-body">
          <section
            id="kyc-verify-wizard-p-0"
            role="tabpanel"
            className="body current"
          >
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div className="mb-3">
                  <label htmlFor="categoryId" className="form-label">
                    Spare Part Category
                  </label>
                  <select
                    id="categoryId"
                    className="form-control"
                    value={formData.categoryId}
                    onChange={(e) =>
                      setFormData({ ...formData, categoryId: e.target.value })
                    }
                  >
                    <option value="" disabled>
                      Select Category
                    </option>
                    {sparePartCategory.map((cat) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="mb-3">
                  <label htmlFor="partname" className="form-label">
                    Spare Part Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="partname"
                    placeholder="Enter Spare Part Name"
                    value={formData.partname}
                    onChange={(e) =>
                      setFormData({ ...formData, partname: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="mb-3">
                  <label htmlFor="partno" className="form-label">
                    Spare Part Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="partno"
                    placeholder="Enter Spare Part Number"
                    value={formData.partno}
                    onChange={(e) =>
                      setFormData({ ...formData, partno: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="mb-3">
                  <label htmlFor="unitPrice" className="form-label">
                    Unit Price
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="unitPrice"
                    placeholder="Enter Unit Price"
                    value={formData.unitPrice}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        unitPrice: parseFloat(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="mb-3">
                  <label htmlFor="qty" className="form-label">
                    Quantity
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="qty"
                    placeholder="Enter Quantity"
                    value={formData.qty}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        qty: parseInt(e.target.value),
                        qtyAvailable:
                          parseInt(e.target.value) - formData.qtyUsed,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="actions clearfix d-flex justify-content-end">
              <button
                className="btn btn-primary waves-effect waves-light"
                onClick={handleSubmit}
                role="menuitem"
                style={{ cursor: "pointer" }}
              >
                {loading ? <FNSpinner /> : "Add Spare Part"}
              </button>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AddSpareStore;

// import { toast } from "react-toastify";
// import FNSpinner from "../../../../components/FNSpinner";
// import API from "../../../../helpers/api";
// import { useEffect, useState } from "react";
// import moment from "moment";

// const AddSpareStore = ({ close, refresh }) => {
//   const [loading, setLoading] = useState(false);
//   const [formData, setFormData] = useState({ code: "", name: "" });

//   const [sparePartCategory, setSpareCategory] = useState([]);

//   const loadCategories = async () => {
//     // setLoading(true);
//     try {
//       const res = await API.get(`/sparecategory`);
//       const sparecategory = res?.data.sparecategory.map((spare) => ({
//         ...spare,
//         createdAt: moment(spare.createdAt).format("YYYY-MM-DD"),
//       }));

//       setSpareCategory(sparecategory);
//       // setLoading(false);
//     } catch (error) {
//       console.log("error", error);
//       // setLoading(false);
//     }
//   };
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       const response = await API.post("/sparecategory", formData);
//       console.log(response);
//       setLoading(false);
//       close();
//       refresh();
//       toast.success(`Spare Part Category Has Been Added Successfully`);
//     } catch (error) {
//       console.log("error", error);
//       setLoading(false);
//       toast.error("Error while Adding Spare Part Category");
//     }
//   };

//   useEffect(() => {
//     loadCategories();
//   }, []);

//   return (
//     <>
//       {" "}
//       <div class="card custom-card">
//         <div class="card-body">
//           <section
//             id="kyc-verify-wizard-p-0"
//             role="tabpanel"
//             aria-labelledby="kyc-verify-wizard-h-0"
//             class="body current"
//             aria-hidden="false"
//           >
//             <div class="row">
//               <div class="col-lg-12">
          //     <pre className="mt-4 p-3 bg-light rounded">
          //   {JSON.stringify(sparePartCategory, null, 2)}
          // </pre>
//                 <div class="mb-3">
//                   <label for="kycfirstname-input" class="form-label">
//                     Spare Part Category
//                   </label>
//                   {/* <select
//                     type="text"
//                     class="form-control"
//                     placeholder="Enter Spare Category Code"
//                     value={formData.categoryId}
//                     options={sparePartCategory.map(cat,k)=>{value: cat.id, label: cat.name}}
//                   /> */}
//                 </div>
//               </div>
//               <div class="col-lg-12">
//                 <div class="mb-3">
//                   <label for="kycselectcity-input" class="form-label">
//                     Spare Part Category Name
//                   </label>
//                   <input
//                     type="text"
//                     class="form-control"
//                     placeholder="Enter Spare Category Name"
//                     value={formData.name}
//                     onChange={(e) =>
//                       setFormData({ ...formData, name: e.target.value })
//                     }
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="actions clearfix">
//               <button
//                 className="btn btn-primary waves-effect waves-light"
//                 onClick={handleSubmit}
//                 role="menuitem"
//                 style={{ cursor: "pointer" }}
//               >
//                 {loading ? <FNSpinner /> : "Add Spare Part Category"}
//               </button>
//             </div>
//           </section>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AddSpareStore;
