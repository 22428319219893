"use client";

import { useState, useEffect } from "react";
import API from "../../../helpers/api";
import Jobcard from "./jobcard.png";
import FNSpinner from "../../../components/FNSpinner";
import Select from "react-select";
import EditSpare from "./EditSpare";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function EditJobCard({ match }) {
  const { id } = match.params;
  const [jobCard, setJobCard] = useState([]);
  const [jobCardId, setJobCardId] = useState();
  const [jobCardSpare, setJobCardSpare] = useState([]);
  const [loading, setLoading] = useState(true);
  const [vehicle, setVehicle] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [selectedParts, setSelectedParts] = useState([]);

  const [vehicleId, setVehicleId] = useState(jobCard?.vehicleId);
  const [date, setDate] = useState(jobCard?.date || "");
  const [timeIn, setTimeIn] = useState(jobCard?.timeIn || "");
  const [timePromised, setTimePromised] = useState(jobCard?.timePromised || "");
  const [currency, setCurrency] = useState(jobCard?.currency);
  const [serviceAdvisor, setServiceAdvisor] = useState(
    jobCard?.serviceAdvisor || ""
  );
  const [dateOfSale, setDateOfSale] = useState(jobCard?.dateOfSale || "");
  const [dateOfDelivery, setDateOfDelivery] = useState(
    jobCard?.dateOfDelivery || ""
  );
  const [driverOrCustomerName, setDriverOrCustomerName] = useState(
    jobCard?.driverOrCustomerName || ""
  );
  const [signature, setSignature] = useState(jobCard?.signature || "");
  const [testorSignature, setTestorSignature] = useState(
    jobCard?.testorSignature || ""
  );
  const [workshopManager, setWorkshopManager] = useState(
    jobCard?.workshopManager || ""
  );
  const [dateClosed, setDateClosed] = useState(jobCard?.dateClosed || "");
  const [dateTested, setDateTested] = useState(jobCard?.dateTested || "");
  const [jack, setJack] = useState(jobCard?.jack || false);
  const [wheelSpanner, setWheelSpanner] = useState(
    jobCard?.wheelSpanner || false
  );
  const [windscreenDamage, setWindscreenDamage] = useState(
    jobCard?.windscreenDamage || ""
  );
  const [paymentMethod, setPaymentMethod] = useState(jobCard?.paymentMethod);
  const [fuele, setFuele] = useState(jobCard?.fuele || "");
  const [fuelPlusOrMinus, setFuelPlusOrMinus] = useState(
    jobCard?.fuelPlusOrMinus || ""
  );
  const [fuelf, setFuelf] = useState(jobCard?.fuelf || "");
  const [workItems, setWorkItems] = useState(jobCard?.workItems || []);

  const fetchJobCard = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/jobcard/${id}`);
      console.log("Job Cards ===>", res);
      const job = res?.data.job || {};
      loadVehicles();
      fetchVehicleDetails(job.vehicle.id);
      setJobCard(job);

      setVehicleId(job.vehicleId);
      setDate(job.date);
      setTimeIn(job.timeIn);
      setTimePromised(job.timePromised);
      setCurrency(job.currency);
      setServiceAdvisor(job.serviceAdvisor);
      setDateOfSale(job.dateOfSale);
      setDateOfDelivery(job.dateOfDelivery);
      setDriverOrCustomerName(job.driverOrCustomerName);
      setSignature(job.signature);
      setTestorSignature(job.testorSignature);
      setWorkshopManager(job.workshopManager);
      setDateClosed(job.dateClosed);
      setDateTested(job.dateTested);
      setJack(job.jack);
      setWheelSpanner(job.wheelSpanner);
      setWindscreenDamage(job.windscreenDamage);
      setPaymentMethod(job.paymentMethod);
      setFuele(job.fuele);
      setFuelPlusOrMinus(job.fuelPlusOrMinus);
      setFuelf(job.fuelf);
      setJobCardId(job.id);

      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const loadVehicles = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/vehicle`);
      console.log(res);
      setVehicles(res?.data.vehicle);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  const handleChangeVehicle = (selectedOption) => {
    setVehicleId(selectedOption.value);
    fetchVehicleDetails(selectedOption.value);
  };

  const fetchVehicleDetails = async (id) => {
    try {
      const res = await API.get(`/vehicle/${id}`);
      console.log(res);
      setVehicle(res?.data.vehicle);
    } catch (error) {
      console.log("Error fetching vehicle details", error);
    }
  };

  const loadJobCardSpare = async () => {
    try {
      const res = await API.get(`/jobcard/spare/${id}`);
      console.log("-----job spare", res?.data?.spareparts);

      setJobCardSpare(res?.data?.spareparts || []);

      setSelectedParts((old) => [
        ...res?.data?.spareparts.map((job) => ({
          key: job.id,
          value: Number(job.spareId),
          label: job.partname,
          unitPrice: job.unitPrice,
        })),
      ]);
    } catch (error) {
      console.error("Error fetching items:", error);
    }
  };

  // useEffect(() => {
  //   fetchJobCard();
  // }, [id]);

  useEffect(() => {
    if (id) {
      fetchJobCard();
      loadJobCardSpare();
    }
  }, []);

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestData = {
      data: {
        vehicleId,
        date,
        timeIn,
        timePromised,
        currency,
        serviceAdvisor,
        dateOfSale,
        dateOfDelivery,
        driverOrCustomerName,
        signature,
        testorSignature,
        workshopManager,
        dateClosed,
        dateTested,
        jack,
        wheelSpanner,
        windscreenDamage,
        paymentMethod,
        fuele,
        fuelPlusOrMinus,
        fuelf,
      },
      jobCardId,
      spare: selectedParts,
    };
    console.log("request Data====", requestData);
    try {
      const response = await API.patch(`/jobcard/${id}`, requestData);
      setLoading(false);
      history.push("/jobcard/view");
      toast.success(`Job Card Has Been Updated Successfully`);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      toast.error("Error while Updating Job Card");
    }
  };

  console.log("....select-spare", selectedParts);

  return (
    <div className="row">
      <div className="col-12">
        {" "}
        <>
          <div className="row">
            <div className="col-12">
              <div className="d-sm-flex mb-4">
                <div
                  style={{ width: "100%", height: "20vh", overflow: "hidden" }}
                >
                  <img
                    src={Jobcard}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Select Vehicle Number Plate
                </label>
                <Select
                  defaultValue={vehicleId}
                  onChange={handleChangeVehicle}
                  options={vehicles?.map((vehicle) => ({
                    value: vehicle.id,
                    label: vehicle.numberplate,
                  }))}
                  placeholder={jobCard?.vehicle?.numberplate}
                />
              </div>
            </div>
            <div class="col-lg-3">
              <div class="mb-3">
                <label for="kycselectcity-input" class="form-label">
                  Select Currency
                </label>
                <select
                  className="form-select"
                  aria-label="Select Currency"
                  value={currency}
                  onChange={(e) => setCurrency(e.target.value)} // Corrected onChange handler
                >
                  <option value="UGX">UGX</option>
                  <option value="USD">USD</option>
                  <option value="EURO">EURO</option>
                  <option value="KES">KES</option>
                </select>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div class="table-responsive">
                    <table class="table table-bordered border-secondary table-sm">
                      <tbody>
                        <tr>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Customer Name
                          </th>
                          <td>
                            {vehicle.customer ? vehicle.customer.fullname : ""}
                          </td>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Address
                          </th>
                          <td>
                            {vehicle.customer ? vehicle.customer.address : ""}
                          </td>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Contact Person
                          </th>
                          <td>
                            {vehicle.customer ? vehicle.customer.tin : ""}
                          </td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Telephone Number
                          </th>
                          <td>{vehicle.make ? vehicle.make.name : ""}</td>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Model
                          </th>
                          <td>{vehicle.make ? vehicle.make.name : ""}</td>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Model
                          </th>
                          <td>{vehicle.model ? vehicle.model.name : ""}</td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Engine Number
                          </th>
                          <td>{vehicle ? vehicle.engineno : ""}</td>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Engine Size
                          </th>
                          <td>{vehicle ? vehicle.enginesize : ""}</td>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Chassis Number
                          </th>
                          <td>{vehicle ? vehicle.chassisno : ""}</td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            YOM
                          </th>
                          <td>{vehicle ? vehicle.year : ""}</td>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Mileage
                          </th>
                          <td>{vehicle ? vehicle.mileage : ""}</td>
                          <th
                            scope="row"
                            style={{ backgroundColor: "#00bfff" }}
                          >
                            Transmission
                          </th>
                          <td>{vehicle ? vehicle.transmission : ""}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <h4 className="py-3">
                I did not leave any personal effects in my vehicle
              </h4>
              <div class="row">
                <div class="col-6">
                  <div class="mb-3 row">
                    <div className="col-3">
                      <label for="kycselectcity-input" class="form-label ">
                        Driver or Customer Name
                      </label>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={jobCard?.driverOrCustomerName}
                        // onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3 row">
                    <div className="col-3">
                      {" "}
                      <label for="kycselectcity-input" class="form-label ">
                        Signature
                      </label>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={jobCard?.signature}
                        // onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-2 row">
                    <div className="col-3">
                      {" "}
                      <label for="kycselectcity-input" class="form-label">
                        Testor Signature
                      </label>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={jobCard?.testorSignature}
                        // onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3 row">
                    <div className="col-3">
                      <label for="kycselectcity-input" class="form-label ">
                        Workshop Manager
                      </label>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={jobCard?.workshopManager}
                        // onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3 row">
                    <div className="col-3">
                      {" "}
                      <label for="kycselectcity-input" class="form-label ">
                        Date Closed
                      </label>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="date"
                        class="form-control"
                        placeholder=""
                        value={jobCard?.dateClosed}
                        // onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3 row">
                    <div className="col-3">
                      {" "}
                      <label for="kycselectcity-input" class="form-label ">
                        Date of Tested
                      </label>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="date"
                        class="form-control"
                        placeholder=""
                        value={jobCard?.dateTested}
                        // onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="row bg-secondary bg-opacity-10 text-secondary mb-2 py-3">
                    <div class="col-6">
                      <div class="mb-3 row">
                        <div className="col-12">
                          <div className="d-flex flex-row me-3">
                            <label className="me-2">Tools:</label>
                            <div className="form-check form-checkbox-outline form-check-primary mb-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="jack"
                                checked={jobCard?.jack}
                                // onChange={handleInputChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="jack"
                              >
                                Jack
                              </label>
                            </div>
                            <div className="form-check form-checkbox-outline form-check-success">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="wheelspanner"
                                // checked={items.wheelSpanner} // Use checked to bind the value properly
                                // onChange={
                                //   () =>
                                //     setItems({
                                //       ...items,
                                //       wheelSpanner: !items.wheelSpanner,
                                //     }) // Toggles the value
                                // }
                              />

                              <label
                                className="form-check-label"
                                htmlFor="wheelspanner"
                              >
                                Wheelspanner
                              </label>
                            </div>
                          </div>

                          {/* Fuel Group */}
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="mb-3 row">
                        <div className="col-12">
                          {" "}
                          <div className="d-flex flex-row me-5">
                            <label className="me-2">Windscreen Damage:</label>
                            <div className="form-check form-checkbox-outline form-check-primary">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="yes"
                                checked={jobCard?.windscreenDamage}
                                // onChange={handleInputChange}
                              />
                              {/* <label className="form-check-label" htmlFor="yes">
                                Yes
                              </label> */}
                            </div>
                            {/* <div className="form-check form-checkbox-outline form-check-primary">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="no"
                                checked={items.no}
                                onChange={handleCheckboxChange}
                              />
                              <label className="form-check-label" htmlFor="no">
                                No
                              </label>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3 row">
                        <div className="col-12">
                          {" "}
                          <div className="d-flex flex-row me-5">
                            <label className="me-2">Other Damage:</label>
                            <div className="form-check form-checkbox-outline form-check-primary">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="yes"
                                // checked={items.yes}
                                // onChange={handleCheckboxChange}
                              />
                              <label className="form-check-label" htmlFor="yes">
                                Yes
                              </label>
                            </div>
                            <div className="form-check form-checkbox-outline form-check-primary">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="no"
                                // checked={items.no}
                                // onChange={handleCheckboxChange}
                              />
                              <label className="form-check-label" htmlFor="no">
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="mb-3 row ">
                        <div className="col-12">
                          {/* Payment Method Group */}
                          <div className="d-flex flex-row">
                            <label className="me-2">Payment Method:</label>
                            <div className="">
                              <Select
                                value={
                                  paymentMethod
                                    ? {
                                        label: paymentMethod,
                                        value: paymentMethod,
                                      }
                                    : null // No default value, placeholder will show
                                }
                                onChange={(selectedOption) =>
                                  setPaymentMethod(selectedOption?.value)
                                }
                                options={[
                                  { label: "Cash", value: "Cash" },
                                  { label: "Credit", value: "Credit" },
                                ]}
                                placeholder="Select payment method"
                              />
                              {/* <input
                                className=" mx-1"
                                type="text"
                                id="cash"
                                value={paymentMethod}
                                onChange={(e) =>
                                  setPaymentMethod(e.target.value)
                                }
                              /> */}
                              {/* <label
                                className="form-check-label"
                                htmlFor="cash"
                              >
                                Cash
                              </label> */}
                            </div>
                            {/* <div className="form-check form-checkbox-outline form-check-success">
                              <input
                                className="form-check-input mx-1"
                                type="checkbox"
                                id="credit"
                                checked={jobCard?.paymentMethod}
                                // onChange={handleInputChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="credit"
                              >
                                Credit
                              </label>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class=" row">
                    <div className="col-3">
                      <label for="kycselectcity-input" class="form-label ">
                        Technician's Name
                      </label>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="text"
                        class="form-control"
                        placeholder=""
                        value={jobCard?.technicianName}
                        // onChange={handleInputChange}
                        // onChange={(e) => setTechName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3 row">
                    <div className="col-3">
                      <label for="kycselectcity-input" class="form-label ">
                        Date In
                      </label>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="date"
                        class="form-control"
                        placeholder=""
                        value={jobCard?.dateIn}
                        // onChange={handleInputChange}
                        // onChange={(e) => setDateIn(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div class="col-4">
                  <div class="mb-3 row">
                    <div className="col-3">
                      <label for="kycselectcity-input" class="form-label ">
                        Date Out:
                      </label>
                    </div>
                    <div className="col-6">
                      {" "}
                      <input
                        type="date"
                        class="form-control"
                        placeholder=""
                        value={jobCard?.dateOut}
                        // onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="card">
            <div className="card-body">
              <div class="row">
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="kycselectcity-input" class="form-label">
                      Date
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder=""
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="kycselectcity-input" class="form-label">
                      Time In
                    </label>
                    <input
                      type="time"
                      class="form-control"
                      placeholder=""
                      value={timeIn}
                      onChange={(e) => setTimeIn(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-2">
                    <label for="kycselectcity-input" class="form-label">
                      Time Promised
                    </label>
                    <input
                      type="time"
                      class="form-control"
                      placeholder=""
                      value={timePromised}
                      onChange={(e) => setTimePromised(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="kycselectcity-input" class="form-label">
                      Service Advisor
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      value={serviceAdvisor}
                      onChange={(e) => setServiceAdvisor(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="kycselectcity-input" class="form-label">
                      Date of Sale
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder=""
                      value={dateOfSale}
                      onChange={(e) => setDateOfSale(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="kycselectcity-input" class="form-label">
                      Date of Delivery
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder=""
                      value={dateOfDelivery}
                      onChange={(e) => setDateOfDelivery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <h4>I did not leave any personal effects in my vehicle</h4>
            <div className="card-body">
              <div class="row">
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="kycselectcity-input" class="form-label">
                      Driver or Customer Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      value={driverOrCustomerName}
                      onChange={(e) => setDriverOrCustomerName(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="kycselectcity-input" class="form-label">
                      Signature
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      value={signature}
                      onChange={(e) => setSignature(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-2">
                    <label for="kycselectcity-input" class="form-label">
                      Testor Signature
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      value={testorSignature}
                      onChange={(e) => setTestorSignature(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="kycselectcity-input" class="form-label">
                      Workshop Manager
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder=""
                      value={workshopManager}
                      onChange={(e) => setWorkshopManager(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="kycselectcity-input" class="form-label">
                      Date Closed
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder=""
                      value={dateClosed}
                      onChange={(e) => setDateClosed(e.target.value)}
                    />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="mb-3">
                    <label for="kycselectcity-input" class="form-label">
                      Date of Tested
                    </label>
                    <input
                      type="date"
                      class="form-control"
                      placeholder=""
                      value={dateTested}
                      onChange={(e) => setDateTested(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-row me-3">
                  <label className="me-2">Tools:</label>
                  <div className="form-check form-checkbox-outline form-check-primary mb-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="jack"
                      checked={jack}
                      onChange={(e) => setJack(e.target.checked)} // Correctly handle checkbox state
                    />
                    <label className="form-check-label" htmlFor="jack">
                      Jack
                    </label>
                  </div>
                  <div className="form-check form-checkbox-outline form-check-primary">
                    <input
                      className="form-check-input "
                      type="checkbox"
                      id="wheelspanner"
                      checked={wheelSpanner}
                      onChange={(e) => setWheelSpanner(e.target.checked)}
                    />

                    <label className="form-check-label" htmlFor="wheelspanner">
                      Wheelspanner
                    </label>
                  </div>
                </div>

                <div className="d-flex flex-row me-3">
                  <label className="">Fuel:</label>
                  <div className="form-check form-checkbox-outline form-check-primary">
                    <input
                      className="form-check-input mx-1"
                      type="checkbox"
                      id="fuele"
                      checked={fuele}
                      onChange={(e) => setFuele(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="fuelE">
                      Fuel E
                    </label>
                  </div>
                  <div className="form-check form-checkbox-outline form-check-info">
                    <input
                      className="form-check-input mx-1"
                      type="checkbox"
                      id="fuelPlusOrMinus"
                      checked={fuelPlusOrMinus}
                      onChange={(e) => setFuelPlusOrMinus(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="half">
                      Fuel +/-
                    </label>
                  </div>
                  <div className="form-check form-checkbox-outline form-check-info">
                    <input
                      className="form-check-input mx-1"
                      type="checkbox"
                      id="fuelf"
                      checked={fuelf}
                      onChange={(e) => setFuelf(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="full">
                      Fuel F
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-row me-5">
                  <label className="me-2">Windscreen Damage:</label>
                  <div className="form-check form-checkbox-outline form-check-primary">
                    <input
                      className="form-check-input mx-1"
                      type="checkbox"
                      id="yes"
                      checked={windscreenDamage}
                      onChange={() => setWindscreenDamage(true)}
                    />
                    <label className="form-check-label" htmlFor="yes">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-checkbox-outline form-check-primary">
                    <input
                      className="form-check-input mx-1"
                      type="checkbox"
                      id="no"
                      checked={!windscreenDamage}
                      onChange={() => setWindscreenDamage(false)}
                    />
                    <label className="form-check-label" htmlFor="no">
                      No
                    </label>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <label className="me-2">Payment Method:</label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="cash"
                      name="paymentMethod"
                      value="Cash"
                      checked={paymentMethod === "Cash"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="cash">
                      Cash
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="credit"
                      name="paymentMethod"
                      value="Credit"
                      checked={paymentMethod === "Credit"}
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="credit">
                      Credit
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-12">
              <div className="">
                <div className="">
                  <EditSpare
                    selectedParts={selectedParts}
                    setSelectedParts={setSelectedParts}
                    refresh={loadJobCardSpare}
                  />

                  <div className="float-end">
                    <button
                      onClick={handleSubmit}
                      className="btn btn-primary w-md waves-effect waves-light"
                    >
                      {loading ? <FNSpinner /> : "Update Job Card Details"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      {/* <div className="col-4">
        {" "}
        <pre className="mt-4 p-3 bg-light rounded">
          {JSON.stringify(jobCard, null, 2)}
        </pre>
      </div> */}
    </div>
  );
}
