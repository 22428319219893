import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from 'react-select';
import API from "../../../../helpers/api";
import FNSpinner from "../../../../components/FNSpinner";

const AddType = ({ close, refresh }) => {

    const [loading, setLoading] = useState(false);
    const [make, setMake] = useState([]);
    const [makeId, setMakeId] = useState("");
    const [name, setName] = useState("");

    const handleChangeMake = (selectedOption) => {
        setMakeId(selectedOption.value);
    };

    const loadMake = async () => {
        setLoading(true);
        try {
            const res = await API.get(`/make/t`);
            setMake(res?.data.make);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            makeId,
            name
        }

        try {
            const response = await API.post("/model", data);
            console.log(response)
            setLoading(false);
            close();
            refresh();
            toast.success(`Vehicle Model Has Been Added Successfully`);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
            toast.error("Error while Adding Vehicle Model");
        }
    };

    useEffect(() => {
        loadMake();
    }, []);

    return (
        <div class="card custom-card">
            <div class="card-body">
                <section id="kyc-verify-wizard-p-0" role="tabpanel" aria-labelledby="kyc-verify-wizard-h-0" class="body current" aria-hidden="false">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="mb-3">
                                <label for="kycselectcity-input" class="form-label">Vehicle Make</label>
                                <Select
                                    defaultValue={makeId}
                                    onChange={handleChangeMake}
                                    options={make.map(make => ({ value: make.id, label: make.name }))}
                                    placeholder="Select Vehicle Make"
                                />
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="mb-3">
                                <label for="kycselectcity-input" class="form-label">Vehicle Model Name</label>
                                <input type="text" class="form-control" placeholder="Enter Vehicle Model Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="actions clearfix">
                        <button className="btn btn-primary waves-effect waves-light" onClick={handleSubmit} role="menuitem" style={{ cursor: 'pointer' }}>
                            {loading ? <FNSpinner /> : "Add Vehicle Model"}
                        </button>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default AddType