import React from "react";

const DetailsTable = ({ tableData }) => {
 

  return (
    <table  className="table table-bordered border-secondary table-sm">
      <tbody>
        {tableData.map((row, index) => (
          <tr key={index}>
            <th scope="row" style={{ backgroundColor: "#5988FF" }} className="py-2  text-white">{row[0]}</th>
            <td>{row[1] || ""}</td>
            <th scope="row" style={{ backgroundColor: "#5988FF" }} className="py-2 text-white">{row[2]}</th>
            <td>{row[3] || ""}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DetailsTable;
