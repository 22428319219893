import { useEffect, useRef, useState } from "react";
import moment from "moment";
import ReactToPrint from "react-to-print";
import CurrencyFormat from "react-currency-format";
import API from "../../helpers/api";

import footerImage from "../../assets/images/proformaFooter.png";
import { toast } from "react-toastify";
import { LucideDownload } from "lucide-react";

function Print({ image, id }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [supply, setSupply] = useState({});

  const componentRef = useRef();

  const loadSupply = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/supplies/${id}`);
      setSupply(res?.data.supply);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const loadItems = async () => {
    setLoading(true);
    try {
      const res = await API.get(`/supplies/items/${id}`);
      setItems(res?.data.items);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    loadSupply();
  }, []);
  return (
    <>
      <div
        ref={componentRef}
        style={{ minHeight: "100vh", position: "relative" }}
        className="  min d-flex flex-column justify-content-between"
      >
        {" "}
        <div
          className=" d-flex justify-content-center mb-3 fixed-top"
          style={{ right: "-3%", top: "25%" }}
        >
          <div className="col-10">
            <div class="d-print-none">
              <div class="float-end">
                <ReactToPrint
                  trigger={() => (
                    <button class="btn btn-lg btn-warning   w-md waves-effect waves-light">
                      <LucideDownload /> Print Invoice
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid ">
          <div className="row d-flex justify-content-center">
            <div className="col-11">
              <div className="card">
                <div className="card-body">
                  <div
                    className="mb-4"
                    style={{
                      width: "100%",
                      // height: "40vh",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={image}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>

                  <section className="d-flex flex-row justify-content-between">
                    <div>
                      <h4>
                        {" "}
                        M/S : {supply.customer ? supply.customer.fullname : ""}
                      </h4>
                      <h5>
                        {" "}
                        Address:{" "}
                        {supply.customer ? supply.customer.address : ""}
                      </h5>
                    </div>
                    <div>
                      <h5>
                        {" "}
                        Date: {moment(supply.proformaDate).format("YYYY-MM-DD")}
                      </h5>
                      <h5>Invoice No: {supply.id}</h5>
                    </div>
                  </section>
                  <section className="mt-4">
                    {/* <div class="py-2 mt-3">
                                      <h3 class="font-size-15 fw-bold">Job Card Items Worked On</h3>
                                  </div> */}

                    <div class="table-responsive">
                      <table class="table table-striped table-bordered border-secondary table-sm">
                        <thead className="text-white b">
                          <tr className="">
                            <th
                              className="text-white py-2"
                              style={{ backgroundColor: "#5988FF" }}
                              width="350"
                            >
                              Description / Model
                            </th>
                            <th
                              className="text-white py-2"
                              style={{ backgroundColor: "#5988FF" }}
                            >
                              Part No
                            </th>
                            <th
                              className="text-white py-2"
                              style={{ backgroundColor: "#5988FF" }}
                            >
                              Serial No
                            </th>
                            <th
                              className="text-white py-2"
                              style={{ backgroundColor: "#5988FF" }}
                            >
                              Qty
                            </th>
                            <th
                              className="text-white text-end py-2"
                              style={{ backgroundColor: "#5988FF" }}
                            >
                              Unit Price
                            </th>
                            <th
                              className="text-white text-end py-2"
                              style={{ backgroundColor: "#5988FF" }}
                            >
                              Ammount
                            </th>
                            {/* <th class="text-end">{`Amount  
                            (${supply.currency})
                            `}</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {items &&
                            items.map((item) => (
                              <tr key={item.id}>
                                <td>{item.item}</td>
                                <td>{item.partno}</td>
                                <td>{item.id}</td>
                                <td>{item.qty}</td>
                                <td class="text-end">
                                  <CurrencyFormat
                                    value={item.unitprice}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                </td>

                                <td class="text-end">
                                  <CurrencyFormat
                                    value={item.amt}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td colspan="5" class="text-end">
                              <strong>Sub Total Amount</strong>
                            </td>
                            <td class="text-end">
                              <CurrencyFormat
                                value={supply?.subTotal}
                                // value="test"
                                displayType="text"
                                thousandSeparator
                              />
                            </td>
                          </tr>
                          <tr>
                            {supply?.discount ? (
                              <>
                                <td colspan="5" class="border-0 text-end">
                                  <strong>Discount</strong>
                                </td>
                                <td class="text-end">
                                  <CurrencyFormat
                                    value={supply?.discount}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                          <tr>
                            {supply?.discount ? (
                              <>
                                <td colspan="5" class="border-0 text-end">
                                  <strong>Net Total Amount</strong>
                                </td>
                                <td class="text-end">
                                  <CurrencyFormat
                                    value={supply?.netTotal}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                          <tr>
                            {supply?.vat ? (
                              <>
                                <td colspan="5" class="border-0 text-end">
                                  <strong>VAT</strong>
                                </td>
                                <td class="text-end">
                                  <CurrencyFormat
                                    value={supply?.vat}
                                    displayType="text"
                                    thousandSeparator
                                  />
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                          </tr>
                          <tr>
                            <td colspan="5" class="text-end">
                              <strong>{`Gross Total Amount (${supply?.jobcard?.currency})`}</strong>
                            </td>
                            <td class="text-end">
                              <h4 class="m-0">
                                <CurrencyFormat
                                  value={supply?.grossTotal}
                                  displayType="text"
                                  thousandSeparator
                                />
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                  {/* <section>
                                  <p>Notes to client</p>
                              </section> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-3 container-fluid"
          style={{
            width: "100%",
            overflow: "hidden",
          }}
        >
          <img
            src={footerImage}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "bottom",
            }}
          />
        </div>
      </div>
    </>
  );
}

export default Print;
